import React from "react";
import Layout from "../components/layout";
import { css } from '@emotion/core';
import { graphql } from "gatsby"
import moment from "moment"

import { TitleHeader, wrapper } from "../components/defaults/styles/elements"
import { Grid } from "../components/defaults/assets/svg/mobilenav"
import PressFooter from "../components/pressfooter"
import { color } from "../components/defaults/styles/theme"
import AgendaEl from '../components/main/agenda-el';
import SubNav from "../components/main/sub_nav"

const seo = {
  frontmatter: {
    title: "Agenda",
  },
}


const nav_data = [
  {
    name: "Day 1 – Sep 23rd",
    url: "/agenda/day1",
  },
  {
    name: "Day 2 – Sep 24th",
    url: "/agenda/day2"
  },
  {
    name: "Breakout Sessions – Sep 24th",
    url: "/agenda/breakout-sessions"
  }
]

const Agenda = ({data, pageContext}) => {
    const {date, event_day} = pageContext;
    const dateString = moment(date, "YYYY-MM-DD").format("MMMM Do, YYYY – dddd")

    const {event_title} = pageContext;
    return (
      <>
        <Layout seo={seo}>
          <TitleHeader
            title={
              event_title
                ? event_title + " [Day " + event_day + "]"
                : "Agenda [Day " + event_day + "]"
            }
            subtitle={
              "<p><b>" +
              dateString +
              "</b></p><p>Below you will find our agenda so you will know exactly where to be, and when. Get ready to discover the content of EXPO: keynotes, panels, pilot documentaries, thought leaders and lots more! </p>"
            }
            icon={<Grid />}
          />

          <div css={wrapper}>
            <SubNav nav_data={nav_data} />
          </div>

          <section
            css={css`
              background: ${color.main_light};
              position: relative;
              margin-top: -2.5rem;
              /* &:before {
                content: "";
                position: absolute;
                background: white;
                width: 100%;
                height: 1em;
                top: 0;
                left: 0;
              } */
            `}
          >
            <div
              css={[
                wrapper,
                css`
                  padding-bottom: 5rem;
                  padding-top: 5rem;
                  z-index: 1;
                  position: relative;
                  /* max-width: 60rem; */
                `,
              ]}
            >
              {data.allAirtable.edges.map(({ node }, index) => (
                <AgendaEl
                  key={index}
                  title={node.data.agenda_title}
                  start={moment(node.data.agenda_start_time).format("HH:mm")}
                  start_utc={moment(node.data.agenda_start_time)
                    .utc()
                    .format("h:mm A")}
                  duration={new Date(node.data.agenda_duration * 1000)
                    .toISOString()
                    .substr(12, 4)}
                  tags={node.data.agenda_tags}
                  speakers={node.data.agenda_speaker}
                  hosts={node.data.agenda_host}
                  teaser={node.data.agenda_teaser}
                  description={
                    node.data.agenda_description
                      ? node.data.agenda_description.childMarkdownRemark.html
                      : ""
                  }
                  css={css`
                    :not(:last-of-type) {
                      margin-bottom: 1em;
                    }
                  `}
                />
              ))}
            </div>
          </section>
          <PressFooter theme={color.main_light} />
        </Layout>
      </>
    )
}

export default Agenda;


export const query = graphql`
         query Agenda($program_id: [String]) {
           allAirtable(
             filter: { table: { eq: "agenda" }, id: { in: $program_id } }
             sort: { fields: data___agenda_start_time, order: ASC }
           ) {
             edges {
               node {
                 id
                 data {
                   agenda_description {
                     childMarkdownRemark {
                       html
                     }
                   }
                   agenda_start_time
                   agenda_tags
                   agenda_title
                   agenda_duration
                   agenda_teaser
                   agenda_host {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                   agenda_speaker {
                     id
                     data {
                       name
                       profession
                       partner_company {
                         data {
                           name
                         }
                       }
                       startup_company {
                         data {
                           startup
                         }
                       }
                       custom_company
                     }
                   }
                 }
               }
             }
           }
         }
       `