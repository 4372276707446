import React from 'react';
import { css } from '@emotion/core';
import { color } from '../defaults/styles/theme';
import { IconButton } from "../defaults/styles/elements"
import Star from '../defaults/assets/svg/star.svg';
import Volume from '../defaults/assets/svg/volume.svg';
import Compass from '../defaults/assets/svg/compass.svg';
import Calendar from '../defaults/assets/svg/calendar.svg';
import Message from '../defaults/assets/svg/message.svg';
import Play from '../defaults/assets/svg/play.svg';
import Video from '../defaults/assets/svg/video.svg';
import { People, Default } from '../defaults/assets/svg/mobilenav';
import mq from '../defaults/styles/mediaquerys';

const Icons = {
  "All People": People,
  Speakers: Volume,
  "Corporate Champions": Star,
  "EXPO Management": Compass,
  "Day 1 – Sep 23rd": Calendar,
  "Day 2 – Sep 24th": Calendar,
  "Breakout Sessions – Sep 24th": Calendar,
  DE: Message,
  EN: Message,
  "Option One": Play,
  "Option Two": Play,
  "Option Three": Play,
  "Option Four (Youtube)": Play,
}

const getProps = ({ isPartiallyCurrent, href, location }) => {
  return {
    ...(isPartiallyCurrent && href !== "/" && href !== "/rearview"
      ? {
          "data-active": true,
        }
      : href === "/rearview" && (location.pathname === "/rearview" || location.pathname === "/rearview/") 
      ? {
          "data-active": true,
        }
      : {}),
  }
}

const Items = ({className, name, url, ...props}) => {
  let Icon = Default
  if ( Icons[name] !== undefined && !props.rearview ) {
    Icon = Icons[name]
  } else if ( props.rearview ) {
    Icon = Video
  }
  return (
    <IconButton
      className={className}
      name={name}
      url={url}
      icon={<Icon />}
      getProps={getProps}
      css={css`
        padding: 0.375rem 0.7rem;
        display: flex;
        align-items: center;
        text-transform: ${props.rearview ? "capitalize" : "none"}
      `}
    />
  )
}

const SubNav = ({className, nav_data, nav_special, legende, ...props}) => {
  

  return (
    <div
      className={className}
      css={[
        css`
          background: ${color.accent_light};
          position: relative;
          z-index: 1;
          border-radius: 5px;
          padding: 1.5em 1em;
        `,
      ]}
    >
      {legende ? (
        <div
          css={css`
            max-width: 45em;
            p {
              font-size: 0.75em;
              margin-bottom: 0.5em;
              &:last-of-type {
                margin-bottom: 0.75em;
              }
            }
          `}
          dangerouslySetInnerHTML={{ __html: legende }}
        />
      ) : (
        ""
      )}
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          ${mq[1]} {
            flex-direction: row;
          }
        `}
      >
        {nav_data.map((item, index) => (
          <Items
            key={index}
            name={item.name}
            url={item.url}
            {...props}
            css={css`
              color: ${color.main_dark};
              margin-bottom: 0.25em;
              margin-top: 0.25em;
              svg {
                stroke: ${color.main_dark};
              }
              &:before {
                background: none;
                border: 1px solid ${color.main_dark};
              }
              :not(:last-of-type) {
                margin-right: 0.5em;
              }
              &[data-active] {
                cursor: default;
                color: white;
                svg {
                  stroke: white;
                }
                &:before {
                  background: ${color.main_dark};
                }
              }
            `}
          />
        ))}
      </div>
    </div>
  )
}

export default SubNav;