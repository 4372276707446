import React, {useState, useRef} from 'react';
import { css } from '@emotion/core';

import { color } from '../defaults/styles/theme';
import mq from '../defaults/styles/mediaquerys';
import Link from '../link';


const resetcss = css`
  margin: 0;
  padding: 0;
`

const Tag = ({tag}) => {
  return (
    <p
      css={[
        resetcss,
        css`
          color: ${["Mobility", "Production", "Enterprise"].includes(tag) ? "white" : color.main_dark};
          background: ${tag === "Mobility"
            ? color.orange
            : tag === "Production"
            ? color.red
            : tag === "Enterprise"
            ? color.blue
            : "white"};
          border-radius: 5px;
          padding: 0.75em 1.5em;
          font-size: 0.65em;
          text-transform: uppercase;
          font-weight: 900;
          letter-spacing: 0.07em;
          line-height: 1em;
          margin: 0.5em 0;
          :not(:last-of-type) {
            margin-right: 0.75em;
          }
        `,
      ]}
    >
      {tag}
    </p>
  )
}

const Time = ({time, utc}) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      `}
    >
      <time
        css={[
          resetcss,
          css`
            color: white;
            font-size: 1.65rem;
            font-weight: 700;
          `,
        ]}
      >
        {time}
      </time>
      <span
        css={css`
          color: white;
          font-size: 0.6em;
          margin-top: -0.75em;
          text-align: right;
          ${mq[1]} {
            margin-bottom: 0.5em;
          }
        `}
      >
        {utc} (UTC)
      </span>
    </div>
  )
}

const Title = ({title}) => {
  return (
    <h3
      css={[
        resetcss,
        css`
          color: white;
          font-size: 1.25rem;
          line-height: 1.45em;
          font-weight: 500;
          max-width: 50rem;
          ${mq[1]} {
            font-size: 1.65rem;
          }
        `,
      ]}
    >
      {title}
    </h3>
  )
}

const TagLine = ({speakers, teaser}) => {

  return teaser || (speakers && speakers.length > 0) ? (
    <div
      css={css`
        display: flex;
        margin-top: 0.75em;
        max-width: 35rem;
        width: 90%;
        ${mq[1]} {
          margin-top: 0;
        }
      `}
    >
      <p
        css={[
          resetcss,
          css`
            color: white;
            opacity: 0.3;
            font-size: 0.75em;
            line-height: 1.5em;
            ${mq[1]} {
              font-size: 0.85em;
            }
          `,
        ]}
      >
        {teaser
          ? teaser
          : speakers.map((speaker, index) => (
              <span key={index}>
                <b>{speaker.data.name}</b>
                {(speaker.data.partner_company &&
                  speaker.data.partner_company.length > 0) ||
                  (speaker.data.startup_company &&
                    speaker.data.startup_company.length > 0) ||
                  speaker.data.custom_company || speaker.data.profession ? " – " : "" }
                <i>
                  {speaker.data.partner_company &&
                  speaker.data.partner_company.length > 0
                    ? speaker.data.partner_company[0].data.name
                    : speaker.data.startup_company &&
                      speaker.data.startup_company.length > 0
                    ? speaker.data.startup_company[0].data.startup
                    : speaker.data.custom_company
                    ? speaker.data.custom_company
                    : speaker.data.profession ? speaker.data.profession : ""}
                </i>
                {index + 1 < speakers.length ? ", " : ""}
              </span>
            ))}
      </p>
    </div>
  ) : (
    ""
  )
}

const Duration = ({duration}) => {
  return (
    duration ? (
      <p css={[resetcss, css`
        color: white;
        margin-left: 1em;
        ${mq[1]} {
          margin-left: 0;
        }
      `]}>{duration} h</p>
    ) : ""
  )
}

const Description = ({content}) => {
  return (
    content ? (
      <div dangerouslySetInnerHTML={{__html: content}} css={css`
        color: white;
        max-width: 40rem;
        margin-bottom: 1.5em;
        margin-top: 2em;
        font-size: .75em;
        p {
          margin-bottom: .45em;
        }
        ul {
          li {
            margin-bottom: .25em;
          }
        }
      `}/>
    ) : ""
  )
}

const OnStage = ({speakers, hosts, narrow}) => {
  const people_frame = css`
    :not(:first-of-type) {
      margin-top: 1em;
    }
    ${mq[2]} {
      :not(:first-of-type) {
        margin-top: ${narrow ? "1em" : "0"};
        margin-left: ${narrow ? "0" : "1.5em"};
      }
    }
  `
  
  const people_title = css`
    color: white;
    font-size: .75em;
    margin-bottom: .5em;
  `

  return speakers || hosts ? (
    <div
      css={css`
        display: flex;
        margin-top: 2em;
        flex-direction: column;
        max-width: 60rem;
        ${mq[2]} {
          flex-direction: ${narrow ? "column" : "row"};
        }
      `}
    >
      {speakers && speakers.length > 0 ? (
        <div
          css={[
            people_frame,
            css`
              flex: 0 0 calc(100% / 3 * 2 - 1em);
            `,
          ]}
        >
          <p css={people_title}>
            {speakers.length > 1 ? "Speakers" : "Speaker"} of this Session
          </p>
          <People speakers={speakers} />
        </div>
      ) : (
        ""
      )}
      {hosts && hosts.length > 0 ? (
        <div
          css={[
            people_frame,
            css`
              flex: 0 0 calc(100% / 3 - 1em);
            `,
          ]}
        >
          <p css={people_title}>
            {hosts.length > 1 ? "Hosts" : "Host"} of this Session
          </p>
          <People speakers={hosts} single narrow={narrow} />
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  )
}

export const People = ({speakers, single, narrow}) => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
      `}
    >
      {speakers.map((speaker, index) => (
        <PeopleEl
          id={speaker.id}
          single={single}
          narrow={narrow}
          key={index}
          name={speaker.data.name}
          profession={speaker.data.profession}
          company={speaker.data.partner_company && speaker.data.partner_company.length > 0 ? speaker.data.partner_company[0].data.name : speaker.data.startup_company && speaker.data.startup_company.length > 0 ? speaker.data.startup_company[0].data.startup : speaker.data.custom_company ? speaker.data.custom_company : ''}
        />
      ))}
      <PeopleEl placeholder />
    </div>
  )
}

const PeopleEl = ({
  name,
  profession,
  company,
  image,
  placeholder,
  single,
  narrow,
  id
}) => {
  return (
    <Link
      to={"/people/" + id}
      css={css`
        background: white;
        text-decoration: none;
        color: ${color.main_dark};
        border-radius: 5px;
        padding: ${placeholder ? "" : "0.6em 1em"};
        display: inline-flex;
        flex-direction: column;
        margin: 0.25em 0;
        visibility: ${placeholder ? "hidden" : "visible"};
        min-width: 0;
        flex: 0 0 100%;
        :first-of-type {
          margin-left: 0;
        }
        :last-of-type {
          margin-right: 0;
        }
        ${mq[1]} {
          flex: 0 0 calc((100% - 0.5em) / 2);
        }
        ${mq[2]} {
          flex: 0 0 ${single && !narrow ? "100%" : "calc((100% - 0.5em) / 2)"};
        }
        p {
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      `}
    >
      <p
        css={css`
          font-weight: 700;
          font-size: 0.75rem;
        `}
      >
        {name}
      </p>
      <p
        css={css`
          font-size: 0.65em;
        `}
      >
        {profession && profession.length > 65
          ? profession.substring(0, 65).concat("...")
          : profession}
      </p>
      <p
        css={css`
          font-size: 0.65em;
        `}
      >
        {company}
      </p>
    </Link>
  )
}

const ExtendButton = ({onClick, activeAgenda}) => {
  return (
    <button
      onClick={onClick}
      css={css`
        background: none;
        margin-top: 1em;
        padding: 0;
        border: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        bottom: 0;
        left: 0;
        justify-content: flex-start;
        outline: none;
        position: absolute;
        height: 5rem;
        cursor: pointer;
        background: linear-gradient(
          to bottom,
          hsla(0, 0%, 7%, 0) 0%,
          hsla(0, 0%, 7%, 0.066) 7.9%,
          hsla(0, 0%, 7%, 0.133) 14.1%,
          hsla(0, 0%, 7%, 0.203) 19%,
          hsla(0, 0%, 7%, 0.273) 23%,
          hsla(0, 0%, 7%, 0.343) 26.4%,
          hsla(0, 0%, 7%, 0.414) 29.4%,
          hsla(0, 0%, 7%, 0.484) 32.6%,
          hsla(0, 0%, 7%, 0.554) 36.1%,
          hsla(0, 0%, 7%, 0.622) 40.3%,
          hsla(0, 0%, 7%, 0.689) 45.6%,
          hsla(0, 0%, 7%, 0.753) 52.3%,
          hsla(0, 0%, 7%, 0.815) 60.8%,
          hsla(0, 0%, 7%, 0.874) 71.3%,
          hsla(0, 0%, 7%, 0.929) 84.3%,
          hsla(0, 0%, 7%, 0.98) 100%
        );
      `}
    >
      <svg
        css={css`
          stroke: white;
          height: 1em;
          transform: ${activeAgenda ? "rotate(-90deg)" : "rotate(0)"};
          transition: transform 0.4s ease-in-out;
        `}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
      <p
        css={css`
          color: white;
          font-size: 0.75em;
          margin-bottom: 0;
          margin-left: 1em;
        `}
      >
        Read more
      </p>
    </button>
  )
}

const AgendaEl = ({className, title, start, start_utc, duration, description, tags, teaser, speakers, hosts, narrow}) => {
  const [activeAgenda, setActiveAgenda] = useState("")
  const [agendaHeight, setAgendaHeight] = useState("5rem")
  const agendaContent = useRef(null)

  const toggleCollapsableAgenda = () => {
    setActiveAgenda(activeAgenda === "" ? "active" : "")
    setAgendaHeight(
      activeAgenda === "active" ? "5rem" : `${agendaContent.current.scrollHeight + 80}px`
    )
  }


  return (
    <div
      className={className}
      css={css`
        background: ${color.main_dark};
        padding: 1em;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        ${mq[1]} {
          padding: 1.5em 2em;
          flex-direction: row;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: baseline;
          ${mq[1]} {
            align-items: flex-end;
            flex-direction: column;
          }
        `}
      >
        <Time time={start} utc={start_utc} />
        <Duration duration={duration} />
      </div>
      <div
        css={css`
          margin-top: 1.5em;
          ${mq[1]} {
            margin-top: 0;
            padding-left: 3rem;
            flex: 0 1 100%;
          }
        `}
      >
        <Title title={title} />
        
        <TagLine speakers={speakers} teaser={teaser}/>

        {tags && tags.length > 0 ? (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              margin-top: .5em;
            `}
          >
            {tags.map((tag, index) => (
              <Tag tag={tag} key={index} />
            ))}
          </div>
        ) : (
          ""
        )}
        {description || (speakers && speakers.length > 0) ? (
          <div
            ref={agendaContent}
            css={css`
              height: ${agendaHeight};
              overflow: hidden;
              transition: height 0.4s ease-in-out;
              position: relative;
            `}
          >
            <ExtendButton
              onClick={toggleCollapsableAgenda}
              className={activeAgenda}
              activeAgenda={activeAgenda}
            />
            <Description content={description} />
            <OnStage speakers={speakers} hosts={hosts} narrow={narrow} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default AgendaEl;